import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, List, ListItem, ListItemText, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';

const CalendarSelect = ({ open, onClose, rentalId, availableDates, onRentalDatesChange }) => {
  const [selectedDays, setSelectedDays] = useState([]);
  const [bookedTimes, setBookedTimes] = useState({});
  const [rentalList, setRentalList] = useState([]);

  const isSameDay = (day1, day2) => {
    return day1.getFullYear() === day2.getFullYear() &&
           day1.getMonth() === day2.getMonth() &&
           day1.getDate() === day2.getDate();
  };

  const handleDayClick = (day, { selected }) => {
    // Toggle day selection
    if (selected) {
      setSelectedDays(selectedDays.filter(selectedDay => !isSameDay(selectedDay, day)));
    } else if (availableDates.some(availDay => isSameDay(new Date(availDay), day))) {
      setSelectedDays([day]);
    }
  };

  const handleTimeSelection = (timeOfDay) => {
    const dateStr = selectedDays[0].toISOString().split('T')[0];
    setBookedTimes({
      ...bookedTimes,
      [dateStr]: { ...bookedTimes[dateStr], [timeOfDay]: true }
    });
    setRentalList([...rentalList, { date: dateStr, period: timeOfDay }]);
    setSelectedDays([]); // Clear selected days to hide time options but keep calendar open
  };

  const handleDelete = (index) => {
    const newList = [...rentalList];
    const deletedItem = newList.splice(index, 1)[0];
    setRentalList(newList);

    const dateStr = deletedItem.date;
    const timeOfDay = deletedItem.period;
    setBookedTimes({
      ...bookedTimes,
      [dateStr]: {
        ...bookedTimes[dateStr],
        [timeOfDay]: false
      }
    });
  };

  const handleSave = () => {
    onRentalDatesChange(rentalList);
    onClose();
  };

  const renderTimeSelection = () => {
    if (selectedDays.length === 0) {
      return null;
    }

    const dateStr = selectedDays[0].toISOString().split('T')[0];
    const isFullDayBooked = bookedTimes[dateStr] && Object.keys(bookedTimes[dateStr]).length === 3;
    const isMorningBooked = bookedTimes[dateStr] && bookedTimes[dateStr].morning;
    const isAfternoonBooked = bookedTimes[dateStr] && bookedTimes[dateStr].afternoon;
    const isNightBooked = bookedTimes[dateStr] && bookedTimes[dateStr].night;

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', mb: 2 }}>
        <Button onClick={() => handleTimeSelection('morning')} variant="contained" disabled={isMorningBooked || isFullDayBooked} sx={{ mb: 1 }}>Morning</Button>
        <Button onClick={() => handleTimeSelection('afternoon')} variant="contained" disabled={isAfternoonBooked || isFullDayBooked} sx={{ mb: 1 }}>Afternoon</Button>
        <Button onClick={() => handleTimeSelection('night')} variant="contained" disabled={isNightBooked || isFullDayBooked} sx={{ mb: 1 }}>Night</Button>
        <Button onClick={() => handleTimeSelection('fullDay')} variant="contained" disabled={isFullDayBooked}>Full Day</Button>
      </Box>
    );
  };

  const modifiers = {
    available: day => availableDates.some(availDay => isSameDay(new Date(availDay), day)),
    disabled: day => !availableDates.some(availDay => isSameDay(new Date(availDay), day)) || new Date(day) < new Date(),
    selected: day => selectedDays.some(selectedDay => isSameDay(selectedDay, day)),
    halfDayBooked: day => bookedTimes[day.toISOString().split('T')[0]] && Object.keys(bookedTimes[day.toISOString().split('T')[0]]).length > 0 && Object.keys(bookedTimes[day.toISOString().split('T')[0]]).length < 3,
    fullDayBooked: day => bookedTimes[day.toISOString().split('T')[0]] && Object.keys(bookedTimes[day.toISOString().split('T')[0]]).length === 3,
  };

  const modifiersStyles = {
    available: { backgroundColor: '#f9b418' },
    disabled: { color: '#000000', backgroundColor: '#ffffff' },
    selected: { backgroundColor: '#b0e0e6', color: 'black' }, // No color change on select
    halfDayBooked: { backgroundColor: '#f9b418' },
    fullDayBooked: { backgroundColor: '#f9b418', color: 'white' },
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Select Rental Dates</DialogTitle>
      <DialogContent>
        <DayPicker
          selectedDays={selectedDays}
          onDayClick={handleDayClick}
          disabledDays={{ before: new Date() }}
          modifiers={modifiers}
          modifiersStyles={modifiersStyles}
        />
        {renderTimeSelection()}
        <List>
          {rentalList.map((item, index) => (
            <ListItem
              key={index}
              secondaryAction={
                <IconButton edge="end" onClick={() => handleDelete(index)}>
                  <DeleteIcon />
                </IconButton>
              }
            >
              <ListItemText primary={`${item.date}: ${item.period}`} />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CalendarSelect;