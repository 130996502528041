import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  TextField,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  InputAdornment,
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Search as SearchIcon } from '@mui/icons-material';
import Calendar from '../components/Calendar';
import AddressComponent from '../components/Address';
import { db, auth, storage } from '../Firebase';
import { doc, collection, setDoc } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';

const CreateRentalComponent = () => {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [category, setCategory] = useState('');
  const [water, setWater] = useState('');
  const [address, setAddress] = useState({});
  const [images, setImages] = useState(['', '', '']);
  const [availableDates, setAvailableDates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openCalendar, setOpenCalendar] = useState(false);

  const handleInputChange = setter => event => setter(event.target.value);

  const handleImageUpload = async (index, event) => {
    const file = event.target.files[0];
    if (file) {
      const currentUser = auth.currentUser;
      if (currentUser) {
        const userId = currentUser.uid;
        const storageRef = ref(storage, `rentals/${userId}/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);
        uploadTask.on(
          'state_changed',
          snapshot => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log(`Upload is ${progress}% done`);
          },
          error => console.error('Error uploading image:', error),
          async () => {
            const downloadURL = await getDownloadURL(storageRef);
            setImages(prevImages => {
              const updatedImages = [...prevImages];
              updatedImages[index] = downloadURL;
              return updatedImages;
            });
          }
        );
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
        const currentUser = auth.currentUser;
        if (!currentUser) {
            throw new Error('User not authenticated');
        }

        if (!address.formattedAddress) {
            throw new Error('Address is required');
        }

        const rentalData = {
            name,
            description,
            price,
            category,
            water,
            address, // This should now be correctly formatted
            images: images.filter(image => image !== ''),
            availableDates,
            userId: currentUser.uid,
        };

        const docRef = doc(collection(db, 'Rentals'));
        await setDoc(docRef, rentalData);
        navigate(`/rental/${docRef.id}`);
    } catch (error) {
        console.error('Error submitting rental:', error);
        alert(error.message);
    } finally {
        setLoading(false);
    }
};


  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '100vh', backgroundColor: '#FFFFFF' }}>
      <Typography variant="h4" align="center" gutterBottom sx={{ color: '#1976d2', marginBottom: '2rem' }}>Create Rental</Typography>
      <Box component="form" onSubmit={handleSubmit} sx={{ backgroundColor: 'white', width: '100%', maxWidth: '600px' }}>
        <Grid container spacing={2}>
          {[
            { label: 'Name', value: name, onChange: handleInputChange(setName) },
            { label: 'Description', value: description, onChange: handleInputChange(setDescription), multiline: true, rows: 4 },
            { label: 'Price Per HALF DAY', value: price, onChange: handleInputChange(setPrice) },
          ].map((input, index) => (
            <Grid item xs={12} key={index}>
              <TextField {...input} fullWidth required sx={{ marginBottom: '1rem' }} />
            </Grid>
          ))}
          <Grid item xs={12}>
            <FormControl fullWidth required sx={{ marginBottom: '1rem' }}>
              <InputLabel>Category</InputLabel>
              <Select value={category} onChange={handleInputChange(setCategory)}>
                <MenuItem value="">Select a category</MenuItem>
                <MenuItem value="Boats">Boats</MenuItem>
                <MenuItem value="Jet Skis">Jet Skis</MenuItem>
                <MenuItem value="Paddlers">Paddlers</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth required sx={{ marginBottom: '1rem' }}>
              <InputLabel>Water</InputLabel>
              <Select value={water} onChange={handleInputChange(setWater)} displayEmpty startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>}>
                <MenuItem value="" disabled>Search body of water</MenuItem>
                <MenuItem value="Crooked Lake">Crooked Lake</MenuItem>
                <MenuItem value="Little Traverse Bay">Little Traverse Bay</MenuItem>
                <MenuItem value="Walloon Lake">Walloon Lake</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <AddressComponent fullWidth required sx={{ marginBottom: '1rem' }} onAddressSelect={setAddress} />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {images.map((image, index) => (
                <Grid item xs={4} key={index}>
                  <Box
                    sx={{
                      width: '100%',
                      height: '100px',
                      border: '1px solid #ccc',
                      borderRadius: '4px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      position: 'relative',
                    }}>
                    {image ? (
                      <img src={image} alt={`Rental ${index + 1}`} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                    ) : (
                      <IconButton
                        sx={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                          backgroundColor: 'rgba(0, 0, 0, 0.5)',
                          color: 'white',
                          '&:hover': {
                            backgroundColor: 'rgba(0, 0, 0, 0.7)',
                          },
                        }}
                        onClick={() => document.getElementById(`imageInput${index}`).click()}
                      >
                        <AddCircleIcon />
                      </IconButton>
                    )}
                    <input
                      id={`imageInput${index}`}
                      type="file"
                      accept="image/*"
                      style={{ display: 'none' }}
                      onChange={(event) => handleImageUpload(index, event)}
                    />
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Grid>     
          <Grid item xs={12}>
            <Button variant="contained" onClick={() => setOpenCalendar(true)} sx={{ backgroundColor: '#F9B418', '&:hover': { backgroundColor: '#F9A518' }, marginTop: '1rem' }}>Select Dates</Button>
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" fullWidth disabled={loading} sx={{ backgroundColor: '#F9B418', '&:hover': { backgroundColor: '#F9A518' }, marginTop: '1rem' }}>{loading ? 'Submitting...' : 'Create Rental'}</Button>
          </Grid>
        </Grid>
      </Box>

      <Dialog open={openCalendar} onClose={() => setOpenCalendar(false)}>
        <DialogTitle>Select Availability Dates</DialogTitle>
        <DialogContent>
          <Calendar onAvailableDatesChange={setAvailableDates} onSaveDates={() => setOpenCalendar(false)} />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default CreateRentalComponent;
