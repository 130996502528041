import React, { useState, useEffect, useRef } from 'react';
import Header from '../components/Header';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
  Snackbar,
  Alert,
} from '@mui/material';
import { db, auth } from '../Firebase';
import { doc, getDoc, collection, addDoc, updateDoc,setDoc } from 'firebase/firestore';
import ChatModal from '../components/Chat';
import Rent from '../components/Rent';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { GoogleMap, MarkerF } from '@react-google-maps/api';
import Persona from '../components/Persona';

const Rental = () => {
  const { id: rentalId } = useParams();
  const navigate = useNavigate();
  const [rentalData, setRentalData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const currentUser = auth.currentUser;
  const [selectedRentalId, setSelectedRentalId] = useState(null);
  const mapRef = useRef(null);
  const [selectedDates, setSelectedDates] = useState([]);
  const [totalPrice, setTotalPrice] = useState(rentalData ? rentalData.price : 0);
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    const fetchRentalData = async () => {
      setLoading(true);
      setError(null);

      if (!rentalId) {
        setError('Invalid rental ID');
        setLoading(false);
        return;
      }

      try {
        const rentalRef = doc(db, 'Rentals', rentalId);
        const rentalSnapshot = await getDoc(rentalRef);

        if (rentalSnapshot.exists()) {
          const data = rentalSnapshot.data();
          setRentalData({
            ...data,
            latitude: data.address.location.lat,
            longitude: data.address.location.lng,
          });

          setTotalPrice(data.price);
        } else {
          setError('Rental not found');
        }
      } catch (error) {
        console.error('Error fetching rental data:', error);
        setError('Error fetching rental data');
      } finally {
        setLoading(false);
      }
    };

    fetchRentalData();
  }, [rentalId]);

  const handleOpenChat = () => {
    setSelectedRentalId(rentalId);
  };

  const handleCloseChat = () => {
    setSelectedRentalId(null);
  };

  const handleRentalDatesChange = (dates) => {
    setSelectedDates(dates);
    calculateTotalPrice(dates);
  };

  const calculateTotalPrice = (dates) => {
    const halfDays = dates.filter(date => date.period !== 'fullDay').length;
    const fullDays = dates.filter(date => date.period === 'fullDay').length;
    const price = halfDays * rentalData.price + fullDays * rentalData.price * 2;
    setTotalPrice(price);
  };

  const handleRentSubmit = async (orderId) => {
    const bookingID = `${rentalId}_${rentalData.userId}_${currentUser.uid}`;

    if (selectedDates.length === 0) {
      console.log('No dates selected');
      return;
    }

    try {
      const bookingData = {
        id: bookingID,
        rentalId: rentalId,
        ownerId: rentalData.userId,
        renterId: currentUser.uid,
        dates: selectedDates,
        totalPrice: totalPrice,
        orderId: orderId,
        status: true,
        renterReview: false,
        name: rentalData.name,
      };
      const bookingRef = doc(db, 'Bookings', bookingID); // Explicitly setting document ID
      await setDoc(bookingRef, bookingData);

      console.log('Booking created successfully',bookingRef);

      // Update the rental's availableDates by removing the booked dates
      const updatedAvailableDates = rentalData.availableDates.filter(
        (date) => !selectedDates.some((selectedDate) => selectedDate.date === date)
      );
      await updateDoc(doc(db, 'Rentals', rentalId), { availableDates: updatedAvailableDates });

      setSuccessMessage('Booking created successfully!');
      setSelectedDates([]);
      setTotalPrice(rentalData.price);
      navigate(`/your-rental/${bookingRef.id}`);
    } catch (error) {
      console.error('Error creating booking:', error);
      // Handle error scenario
    }
  };

  const handleProfileClick = () => {
    navigate(`/profile/${rentalData.userId}`);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
        <Typography variant="h6">Loading...</Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 4 }}>
        <Typography variant="h6" color="error" gutterBottom>
          {error}
        </Typography>
        <Button variant="contained" color="primary" onClick={() => navigate('/')}>
          Go to Home
        </Button>
      </Box>
    );
  }

  if (!rentalData) {
    return null;
  }

  const renderImages = () => {
    if (rentalData.images && rentalData.images.length > 1) {
      return (
        <Slider {...settings}>
          {rentalData.images.map((image, index) => (
            <Box key={index} sx={{ height: 300 }}>
              <img
                src={image}
                alt={`${rentalData.name} ${index + 1}`}
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              />
            </Box>
          ))}
        </Slider>
      );
    } else if (rentalData.images && rentalData.images.length === 1) {
      return (
        <Box sx={{ height: 300 }}>
          <img
            src={rentalData.images[0]}
            alt={rentalData.name}
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          />
        </Box>
      );
    } else {
      return (
        <Box
          component="img"
          height="300"
          src="https://via.placeholder.com/600x300?text=No+Image+Available"
          alt="No Image Available"
        />
      );
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Header />
      <Typography variant="h4" gutterBottom>
        {rentalData.name}
      </Typography>
      <Card sx={{ maxWidth: 600, width: '100%', paddingBottom: 2, marginBottom: 6 }}>
        {renderImages()}
        <CardContent>
          <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
            <div onClick={handleProfileClick} style={{ cursor: 'pointer' }}>
              <Persona userId={rentalData.userId} size={60} />
            </div>
          </Box>
          <Typography variant="body1" color="text.secondary" gutterBottom>
            {rentalData.description}
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            {rentalData.water}, {rentalData.address.formattedAddress}
          </Typography>
          <Box sx={{ height: 300, marginTop: 2 }}>
            <GoogleMap
              onLoad={map => mapRef.current = map}
              mapContainerStyle={{ width: '100%', height: '100%' }}
              center={{ lat: rentalData.latitude, lng: rentalData.longitude }}
              zoom={12}
            >
              <MarkerF
                position={{ lat: rentalData.address.location.lat, lng: rentalData.address.location.lng }}
                icon={{
                  url: 'https://firebasestorage.googleapis.com/v0/b/wakehorse-696e9.appspot.com/o/wakehorseLogo.svg?alt=media&token=474dd75b-862f-4f88-acfc-eb2c89223c4c',
                  scaledSize: new window.google.maps.Size(40, 40),
                  origin: new window.google.maps.Point(0, 0),
                  anchor: new window.google.maps.Point(20, 40),
                }}
                title={rentalData.name}
              />
            </GoogleMap>
          </Box>
        </CardContent>
        <CardActions>
          {currentUser && currentUser.uid !== rentalData.userId && (
            <Button variant="contained" color="primary" onClick={handleOpenChat}>
              Message Owner
            </Button>
          )}
        </CardActions>
      </Card>
      {rentalData &&
      (
      <Rent
      rentalId={rentalId}
      rentalData={rentalData}
      onRentalDatesChange={handleRentalDatesChange}
      totalPrice={selectedDates.length > 0 ? totalPrice : rentalData.price}
      selectedDates={selectedDates}
      onSubmit={handleRentSubmit}
      />
      )
    }

      {selectedRentalId && (
        <ChatModal
          renterId={rentalData.userId}
          rentalId={rentalId}
          userId={currentUser ? currentUser.uid : null}
          onClose={handleCloseChat}
        />
      )}

      <Snackbar
        open={successMessage !== ''}
        autoHideDuration={3000}
        onClose={() => setSuccessMessage('')}
      >
        <Alert onClose={() => setSuccessMessage('')} severity="success" sx={{ width: '100%' }}>
          {successMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Rental;