import React, { useState, useEffect, useRef } from 'react';
import { Box, TextField, Button, List, ListItem, Typography } from '@mui/material';
import { collection, query, doc, orderBy,getDoc, addDoc, serverTimestamp,Timestamp, onSnapshot, arrayUnion, updateDoc, where } from 'firebase/firestore';
import { db, auth } from '../Firebase';  // Ensure Firebase is correctly initialized in this module
import { useLocation } from 'react-router-dom';

const Conversation = () => {
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [conversationData, setConversationData] = useState({});
    let location =  useLocation();
    let conversationId = location.pathname.split("/").pop();
    const userId = auth.currentUser;
    const endOfMessagesRef = useRef(null);

    useEffect(() => {
        if (!userId || !conversationId) return;

        const chatDocRef = doc(db, 'Chats', conversationId);

        const unsubscribe = onSnapshot(chatDocRef, async (docSnapshot) => {
            if (docSnapshot.exists()) {
                const data = docSnapshot.data();
                console.log("Document data:", data);

                // Handle fetching related booking data if rentalId is available
                if (data.rentalId) {
                    const bookingID = `${data.rentalId}_${data.ownerId}_${userId}`;
                    const rentalDocRef = doc(db, 'Rentals', data.rentalId);

                    try {
                        const rentalSnapshot = await getDoc(rentalDocRef);
                        if (rentalSnapshot.exists()) {
                            setConversationData(rentalSnapshot.data());
                        } else {
                            console.log("No rental found with ID:", bookingID);
                        }
                    } catch (error) {
                        console.error("Error fetching rental data:", error);
                    }
                }

                setMessages(data.messages || []);
                endOfMessagesRef.current?.scrollIntoView({ behavior: "smooth" });
            } else {
                console.log("No such document!");
            }
        }, (error) => {
            console.error("Error getting document:", error);
        });

        return () => unsubscribe(); // Clean up the subscription
    }, [conversationId, userId]);

    const handleSendMessage = async () => {
        if (!newMessage.trim()) return;  // Prevent sending empty messages

        const chatDocRef = doc(db, 'Chats', conversationId);
        try {
            await updateDoc(chatDocRef, {
                messages: arrayUnion({
                    userId: userId.uid,
                    message: newMessage,
                    timestamp: Timestamp.fromDate(new Date()),  // Client-side timestamp
                }),
                updatedAt: serverTimestamp()  // Optionally update an 'updatedAt' field
            });
            setNewMessage('');  // Clear the input after sending
        } catch (error) {
            console.error('Error updating messages:', error);
        }
    };

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '85vh',
            p: 2
        }}>
            <Typography variant="h5" backgroundColor="#03468d" color={"white"} padding={1} >{conversationData.name}</Typography>
            <Typography variant="h5" backgroundColor="#03468d" color={"white"} padding={1} gutterBottom>{conversationData.water}</Typography>
            <List sx={{
                flexGrow: 1,
                overflow: 'auto',
                mb: 1
            }}>
                {messages.map(msg => (
                    <ListItem key={msg.id}>
                        <Typography color="text.secondary" component="span">
                            {msg.userId === userId.uid ? 'You' : 'Other'}: 
                        </Typography>
                        {msg.message}
                    </ListItem>
                ))}
                <div ref={endOfMessagesRef}></div>
            </List>
            <Box component="form" onSubmit={e => { e.preventDefault(); handleSendMessage(); }}
                 sx={{
                    display: 'flex',
                    flexDirection: 'column'
                 }}>
                <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Type a message..."
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    sx={{ mb: 1 }}
                />
                <Button variant="contained" onClick={handleSendMessage} disabled={!newMessage.trim()}>
                    Send
                </Button>
            </Box>
        </Box>
    );
  };
  
  export default Conversation;
  