import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Avatar,
  Typography,
  Alert,
  Button,
  IconButton,
} from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import { db } from '../Firebase';
import { doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';

const ViewProfile = ({ uid, isCurrentUser }) => {
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isProfileComplete, setIsProfileComplete] = useState(false);
  const [averageRating, setAverageRating] = useState(0);

  useEffect(() => {
    const fetchUserData = async () => {
      setLoading(true);
      setError(null);
      try {
        if (uid) {
          const userRef = doc(db, 'Users', uid);
          const userSnapshot = await getDoc(userRef);
          if (userSnapshot.exists()) {
            const userData = userSnapshot.data();
            setProfileData(userData);
            setIsProfileComplete(
              userData.username &&
              userData.email &&
              userData.phoneNumber &&
              userData.firstName &&
              userData.lastName &&
              userData.bio
            );
          } else {
            setError('User data not found');
          }
        } else {
          setError('User ID not provided');
        }
      } catch (error) {
        setError('Error fetching user data');
        console.error('Error fetching user data:', error);
      } finally {
        setLoading(false);
      }
    };

    const fetchAverageRating = async () => {
      try {
        const reviewsSnapshot = await getDocs(query(collection(db, 'Reviews'), where('userId', '==', uid)));
        const reviews = reviewsSnapshot.docs.map((doc) => doc.data());
        const totalRating = reviews.reduce((sum, review) => sum + review.rating, 0);
        const averageRating = reviews.length > 0 ? totalRating / reviews.length : 0;
        setAverageRating(averageRating);
      } catch (error) {
        console.error('Error fetching average rating:', error);
      }
    };

    fetchUserData();
    fetchAverageRating();
  }, [uid]);

  const handleMyRentalsClick = () => {
    navigate('/your-rentals');
  };

  const handleMyListingsClick = () => {
    navigate('/your-listings');
  };

  const handleStarClick = () => {
    navigate(`/reviews/${uid}`);
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '200px',
          textAlign: 'center',
          padding: '2rem',
        }}
      >
        <Typography variant="h6">Loading...</Typography>
        <Typography variant="body1">Please wait while we fetch the profile data.</Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '200px',
          textAlign: 'center',
          padding: '2rem',
        }}
      >
        <Typography variant="h6" color="error" gutterBottom>
          {error}
        </Typography>
        <Typography variant="body1">
          If you are seeing this error, please try refreshing the page or contacting our support team for assistance.
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ width: '100%', position: 'relative' }}>
      <Header />
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          height: '200px',
          backgroundImage: `url(${profileData?.coverImage || ''})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: '-75px',
          zIndex: 1,
        }}
      >
        <Avatar
          alt={profileData?.username || 'User'}
          src={profileData?.profileImage || ''}
          sx={{ width: 150, height: 150 }}
        />
        {!isProfileComplete && isCurrentUser && (
          <Alert severity="warning" sx={{ marginTop: '2rem', marginBottom: '2rem' }}>
            Please complete your profile before renting or listing items. Incomplete profiles may result in limited functionality and visibility on our platform. Take a moment to fill in all the required fields in the Edit Profile section.
          </Alert>
        )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: '2rem',
            marginBottom: '2rem',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
            <Typography variant="h6" align="center" gutterBottom>
              Rating:
            </Typography>
            <IconButton onClick={handleStarClick} sx={{ ml: 1 }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {[1, 2, 3, 4, 5].map((star) => (
                  <StarIcon
                    key={star}
                    sx={{
                      color: star <= averageRating ? '#F9B418' : 'rgba(0, 0, 0, 0.26)',
                      fontSize: 24,
                    }}
                  />
                ))}
                <Typography variant="h6" color="text.secondary" sx={{ ml: 0.5 }}>
                  {averageRating.toFixed(1)}
                </Typography>
              </Box>
            </IconButton>
          </Box>
          <Typography variant="h5" align="center" gutterBottom>
            Username: {profileData?.username || ''}
          </Typography>
          <Typography variant="subtitle1" align="center" gutterBottom>
            Name: {profileData?.firstName || ''} {profileData?.lastName || ''}
          </Typography>
          <Typography variant="body1" align="center" gutterBottom>
            Bio: {profileData?.bio || 'No bio available'}
          </Typography>
          {profileData?.joinedDate && (
            <Typography variant="body2" color="textSecondary" gutterBottom>
              Joined: {profileData.joinedDate}
            </Typography>
          )}
        </Box>
        {isCurrentUser && (
          <Box sx={{ width: '100%', marginTop: '2rem', marginBottom: '2rem' }}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              sx={{ marginBottom: '1rem' }}
              onClick={handleMyRentalsClick}
            >
              My Rentals
            </Button>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleMyListingsClick}
            >
              My Listings
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ViewProfile;