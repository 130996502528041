import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate, Link } from 'react-router-dom';
import {
  Box,
  Typography,
  Card,
  CardContent,
  Button,
  CardMedia,
  CardActions,
  Snackbar
} from '@mui/material';
import { GoogleMap, MarkerF } from '@react-google-maps/api';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import { db, auth } from '../Firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReviewRental from '../components/ReviewRental';
import Persona from '../components/Persona';
import ChatModal from '../components/Chat';

const YourRental = () => {
  const { rentalId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const refreshRentals = location.state?.refreshRentals;
  const currentUser = auth.currentUser;
  const [listingData, setListingData] = useState(null);
  const [rentalData, setRentalData] = useState(null);
  const [openReviewDrawer, setOpenReviewDrawer] = useState(false);
  const [closeChatModal, setCloseChatModal] = useState(false);

  console.log('rentalId:', rentalId);
  console.log('listingData:', listingData);
  
  const handleOpenChat = () => {
    setCloseChatModal(!closeChatModal);
  };

  useEffect(() => {
    const fetchRentalData = async () => {
      try {
        const listingRef = doc(db, 'Bookings', rentalId);
        const listingSnapshot = await getDoc(listingRef);
        if (listingSnapshot.exists()) {
          const bookingData = listingSnapshot.data();
          console.log('Booking Data:', bookingData);
          setListingData(bookingData);

          const rentalRef = doc(db, 'Rentals', bookingData.rentalId);
          const rentalSnapshot = await getDoc(rentalRef);
          if (rentalSnapshot.exists()) {
            const rental = rentalSnapshot.data();
            console.log('Rental Data:', rental);
            setRentalData(rental);
          }
        }
      } catch (error) {
        console.error('Error fetching rental data:', error);
      }
    };

    if (rentalId) {
      fetchRentalData();
    }
  }, [rentalId]);

  const handleReviewSubmit = async () => {
    try {
      const listingRef = doc(db, 'Bookings', rentalId);
      await updateDoc(listingRef, { renterReview: true });
      setOpenReviewDrawer(false);
      if (refreshRentals) {
        refreshRentals();
      }
      navigate('/');
    } catch (error) {
      console.error('Error submitting review:', error);
    }
  };

  const handleOwnerProfileClick = () => {
    navigate(`/profile/${rentalData.userId}`);
  };

  const handleRenterProfileClick = () => {
    navigate(`/profile/${listingData.renterId}`);
  };

  if (!listingData || !rentalData) {
    return <Typography>Loading...</Typography>;
  }

  const { dates, totalPrice } = listingData;

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Typography variant="h4" gutterBottom>
        {rentalData.name}
      </Typography>
      <Card sx={{ maxWidth: 600, width: '100%', paddingBottom: 2, marginBottom: 6 }}>
        <Slider {...settings}>
          {rentalData.images.map((image, index) => (
            <CardMedia
              key={index}
              component="img"
              height="300"
              image={image}
              alt={`${rentalData.name} ${index + 1}`}
            />
          ))}
        </Slider>
        <CardContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: 2 }}>
            <Typography variant="subtitle1" gutterBottom>
              Owner
            </Typography>
            <Box onClick={handleOwnerProfileClick} style={{ cursor: 'pointer' }}>
              <Persona userId={rentalData.userId} />
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: 2 }}>
            <Typography variant="subtitle1" gutterBottom>
              Renter
            </Typography>
            <Box onClick={handleRenterProfileClick} style={{ cursor: 'pointer' }}>
              <Persona userId={listingData.renterId} />
            </Box>
          </Box>
          <Typography variant="subtitle1" gutterBottom>
            Selected Dates:
          </Typography>
          <ul>
            {dates.map((date, index) => (
              <li key={index}>{`${date.date}: ${date.period}`}</li>
            ))}
          </ul>
          <Typography variant="subtitle1" gutterBottom>
            Total Price: ${totalPrice}
          </Typography>
          <Box sx={{ height: 300, marginTop: 2 }}>
            <GoogleMap
              mapContainerStyle={{ width: '100%', height: '100%' }}
              center={{ lat: rentalData.address.location.lat, lng: rentalData.address.location.lng }}
              zoom={12}
            >
              <MarkerF
                position={{ lat: rentalData.address.location.lat, lng: rentalData.address.location.lng }}
              />
            </GoogleMap>
          </Box>
        </CardContent>
      </Card>
      {!listingData.renterReview && (
        <Button variant="contained" color="primary" sx={{ marginBottom: 2 }} onClick={() => setOpenReviewDrawer(true)}>
          Review Rental
        </Button>
      )}

      <CardActions>
        {currentUser && currentUser.uid !== rentalData.userId && (
          <Button variant="contained" color="primary" onClick={handleOpenChat}>
            Message Owner
          </Button>
        )}
      </CardActions>
      
      {closeChatModal && (
        <ChatModal
          renterId={rentalData.userId}
          rentalId={rentalId}
          userId={currentUser ? currentUser.uid : null}
          onClose={handleOpenChat}
        />
      )}

      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          padding: 2,
          backgroundColor: 'background.paper',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <ChatBubbleIcon />
      </Box>
      <ReviewRental
        open={openReviewDrawer}
        onClose={() => setOpenReviewDrawer(false)}
        onSubmit={handleReviewSubmit}
        rentalId={listingData.rentalId}
      />
    </Box>
  );
};

export default YourRental;