import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, TextField, Typography, Link, Grid, IconButton } from '@mui/material';
import { Google as GoogleIcon } from '@mui/icons-material';
import { auth, googleAuthProvider, db } from '../Firebase';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSignUp, setIsSignUp] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (isSignUp) {
        // Sign up logic
        const { user } = await createUserWithEmailAndPassword(auth, email, password);
        // Save user data to Firestore (only saving the email)
        await setDoc(doc(db, 'Users', user.uid), {
          email: user.email,
        });
      } else {
        // Sign in logic
        await signInWithEmailAndPassword(auth, email, password);
      }
      navigate('/');
    } catch (error) {
      console.error('Error signing in/up:', error);
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      const { user } = await signInWithPopup(auth, googleAuthProvider);
      
      // Check if the user document exists in Firestore
      const userDocRef = doc(db, 'Users', user.uid);
      const userDocSnapshot = await getDoc(userDocRef);
  
      if (!userDocSnapshot.exists()) {
        // If the user document doesn't exist, create it with the user's email
        await setDoc(userDocRef, {
          email: user.email,
        });
      }
  
      navigate('/');
    } catch (error) {
      console.error('Error signing in with Google:', error);
    }
  };

  const handleForgotPassword = () => {
    navigate('/forgot-password');
  };

  const toggleSignUp = () => {
    setIsSignUp((prevState) => !prevState);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        backgroundColor: 'white',
        color: 'black',
      }}
    >
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          paddingTop: '56.25%', // 16:9 aspect ratio
          overflow: 'hidden',
          marginBottom: '1rem',
        }}
      >
        <video
          autoPlay
          loop
          muted
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        >
          <source src="https://firebasestorage.googleapis.com/v0/b/wakehorse-696e9.appspot.com/o/WakeHorse5000.mp4?alt=media&token=b4783f8c-3869-4fc3-be68-37d541753d03" type="video/mp4" />
        </video>
      </Box>
      <Typography variant="h4" align="center" gutterBottom>
        WakeHorse
      </Typography>
      <Typography variant="p" align="center" gutterBottom>
        Rent and find water sport equpiment for your next adventure.
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: 'white',
          borderRadius: '4px',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
          width: '100%',
          maxWidth: '400px',
        }}
      >
        <TextField
          label="Email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          fullWidth
          margin="normal"
          required
        />
        <Button type="submit" variant="contained" color="primary" fullWidth>
          {isSignUp ? 'Sign Up' : 'Sign In'}
        </Button>
        <Button
          variant="outlined"
          color="primary"
          fullWidth
          sx={{ marginTop: '1rem' }}
          onClick={toggleSignUp}
        >
          {isSignUp ? 'Already have an account? Sign In' : 'Create an account'}
        </Button>
        {!isSignUp && (
          <>
            <Typography variant="body1" align="center" sx={{ marginTop: '1rem' }}>
              OR
            </Typography>
            <Grid container spacing={2} justifyContent="center" sx={{ marginTop: '1rem' }}>
              <Grid item>
                <IconButton onClick={handleGoogleSignIn}>
                  <GoogleIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Link
              component="button"
              variant="body2"
              onClick={handleForgotPassword}
              sx={{ marginTop: '1rem', marginBottom: '1rem' }}
            >
              Forgot Password?
            </Link>
          </>
        )}
      </Box>
    </Box>
  );
};

export default LoginPage;