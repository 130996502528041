import React, { useState, useEffect } from 'react';
import { Box, Avatar, Typography, TextField, IconButton, Button } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { db, auth, storage } from '../Firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { useNavigate } from 'react-router-dom';

const EditProfile = ({ user }) => {
  const navigate = useNavigate();
  const [profileImage, setProfileImage] = useState('');
  const [coverImage, setCoverImage] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [bio, setBio] = useState('');

  useEffect(() => {
    const fetchUserData = async () => {
      if (user && user.uid) {
        const userRef = doc(db, 'Users', user.uid);
        const userSnapshot = await getDoc(userRef);
        if (userSnapshot.exists()) {
          const userData = userSnapshot.data();
          setProfileImage(userData.profileImage || '');
          setCoverImage(userData.coverImage || '');
          setUsername(userData.username || '');
          setEmail(userData.email || '');
          setPhoneNumber(userData.phoneNumber || '');
          setFirstName(userData.firstName || '');
          setLastName(userData.lastName || '');
          setBio(userData.bio || '');
        }
      }
    };

    fetchUserData();
  }, [user]);

  const handleImageUpload = async (file, setter) => {
    if (file) {
      const userId = user.uid;
      const storageRef = ref(storage, `rentals/${userId}/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
        'state_changed',
        snapshot => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(`Upload is ${progress}% done`);
        },
        error => console.error('Error uploading image:', error),
        async () => {
          const downloadURL = await getDownloadURL(storageRef);
          setter(downloadURL);
        }
      );
    }
  };

  const handleProfileImageChange = async (e) => {
    const file = e.target.files[0];
    await handleImageUpload(file, setProfileImage);
  };

  const handleCoverImageChange = async (e) => {
    const file = e.target.files[0];
    await handleImageUpload(file, setCoverImage);
  };

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };

  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
  };

  const handleBioChange = (e) => {
    setBio(e.target.value);
  };

  const handleSave = async () => {
    if (user && user.uid) {
      const userRef = doc(db, 'Users', user.uid);
      await setDoc(
        userRef,
        {
          profileImage,
          coverImage,
          username,
          phoneNumber,
          firstName,
          lastName,
          bio,
        },
        { merge: true }
      );
    }
  };

  const handleLogout = async () => {
    try {
      await auth.signOut();
      navigate('/login');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        maxWidth: '600px',
      }}
    >
      <Box sx={{ position: 'relative', marginBottom: '2rem' }}>
        <Avatar
          alt={username}
          src={profileImage}
          sx={{ width: 150, height: 150 }}
        />
        <IconButton
          sx={{
            position: 'absolute',
            bottom: 0,
            right: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            color: 'white',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.7)',
            },
          }}
          onClick={() => document.getElementById('profileImageInput').click()}
        >
          <AddCircleIcon />
        </IconButton>
        <input
          id="profileImageInput"
          type="file"
          accept="image/*"
          style={{ display: 'none' }}
          onChange={handleProfileImageChange}
        />
      </Box>
      <Box sx={{ marginBottom: '2rem' }}>
        <Typography variant="h6" gutterBottom>
          Cover Photo
        </Typography>
        <input
          id="coverImageInput"
          type="file"
          accept="image/*"
          onChange={handleCoverImageChange}
        />
      </Box>
      <Box sx={{ width: '100%', padding: '1rem' }}>
        <TextField
          label="Username"
          value={username}
          onChange={handleUsernameChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Email"
          value={email}
          onChange={handleEmailChange}
          fullWidth
          margin="normal"
          disabled
        />
        <TextField
          label="Phone Number"
          value={phoneNumber}
          onChange={handlePhoneNumberChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="First Name"
          value={firstName}
          onChange={handleFirstNameChange}
          fullWidth
          margin="normal"
          size="small"
        />
        <TextField
          label="Last Name"
          value={lastName}
          onChange={handleLastNameChange}
          fullWidth
          margin="normal"
          size="small"
        />
        <TextField
          label="Bio"
          value={bio}
          onChange={handleBioChange}
          fullWidth
          multiline
          rows={4}
          margin="normal"
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          sx={{ marginTop: '2rem' }}
        >
          Save
        </Button>
        <Button
          variant="contained"
          color="tertiary"
          onClick={handleLogout}
          sx={{ marginTop: '2rem' }}
        >
          Logout
        </Button>
      </Box>
    </Box>
  );
};

export default EditProfile;