import React, { useState } from 'react';
import { Box, Typography, Button, IconButton, Modal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { PaymentForm, ApplePay, CreditCard } from 'react-square-web-payments-sdk';
import { db, auth } from '../Firebase';

const Payment = ({ rentalData, userId, onClose, onSubmit }) => {
  const [selectedDates, setSelectedDates] = useState([]);
  const [paymentResult, setPaymentResult] = useState(null);
  const currentUser = auth.currentUser;
  console.log('Rental Data:', rentalData);
  const handlePaymentFormSubmission = async (paymentResult) => {
    setPaymentResult(paymentResult);
  };

  const handlePaymentFormError = (error) => {
    console.error('Payment form error:', error);
  };

  const handleDateChange = (dates) => {
    setSelectedDates(dates);
  };

  return (
    <Modal open={true} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          outline: 'none',
          width: 400,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
          <Typography variant="h6" gutterBottom>
            {rentalData.name}
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <PaymentForm
          applicationId="sandbox-sq0idb-KgAdbMYgGo-bvlxjiMD0Qg"
          locationId="L0XA2F0AP71W2"
          createVerificationDetails={() => ({
            amount: '10.00',  // This seems incorrectly placed here.
            intent: 'CHARGE',
            currencyCode: 'USD', // This should align with `createPaymentRequest` if the intent is to charge in GBP.
            billingContact: {
              addressLine1: '1567 bloop st',
              locality: 'Chicago',
              postalCode: '60506',
              country: 'US',
              firstName: 'dave',
              lastName: 'Rodiguez',
              email: 'johndoe@example.com',  // Typically required for verification.
              phone: '1234567890',  // Typically required for verification.
            },
          })}
          cardTokenizeResponseReceived={async (token, verifiedBuyer) => {
            console.log(token.token);
            const response = await fetch('https://us-central1-wakehorse-696e9.cloudfunctions.net/processPayment', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ sourceId: token.token }),
            });
            const responseData = await response.json();
            onSubmit(responseData.payment.orderId);
            onClose();
            console.log(responseData);
          }}
          createPaymentRequest={() => ({
            countryCode: 'US',
            currencyCode: 'USD',
            total: {
              amount: '10.00',
              label: 'Rental Payment',
            },
            requestBillingContact: false,
            requestShippingContact: false,
          })}
          onError={handlePaymentFormError}
        >
          <CreditCard />
        </PaymentForm>

      </Box>
    </Modal>

    );
};

export default Payment;