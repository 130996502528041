import React, { useState, useEffect } from 'react';
import { Avatar, Typography, Box, IconButton } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../Firebase';
import { useNavigate } from 'react-router-dom';

const Persona = ({ userId }) => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [averageRating, setAverageRating] = useState(0);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userDoc = await getDocs(query(collection(db, 'Users'), where('__name__', '==', userId)));
        if (!userDoc.empty) {
          const user = userDoc.docs[0].data();
          setUserData(user);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    const fetchAverageRating = async () => {
      try {
        const reviewsSnapshot = await getDocs(query(collection(db, 'Reviews'), where('userId', '==', userId)));
        const reviews = reviewsSnapshot.docs.map((doc) => doc.data());
        const totalRating = reviews.reduce((sum, review) => sum + review.rating, 0);
        const averageRating = reviews.length > 0 ? totalRating / reviews.length : 0;
        setAverageRating(averageRating);
      } catch (error) {
        console.error('Error fetching average rating:', error);
      }
    };

    fetchUserData();
    fetchAverageRating();
  }, [userId]);

  const handleStarClick = () => {
    navigate(`/reviews/${userId}`);
  };

  if (!userData) {
    return null;
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Avatar
        alt={userData.username}
        src={userData.profileImage}
        sx={{ width: 24, height: 24, marginRight: 1 }}
      />
      <Typography variant="body2" color="text.secondary">
        {userData.username}
      </Typography>
      <IconButton onClick={handleStarClick} sx={{ ml: 1 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {[1, 2, 3, 4, 5].map((star) => (
            <StarIcon
              key={star}
              sx={{
                color: star <= averageRating ? '#F9B418' : 'rgba(0, 0, 0, 0.26)',
                fontSize: 16,
              }}
            />
          ))}
          <Typography variant="body2" color="text.secondary" sx={{ ml: 0.5 }}>
            {averageRating.toFixed(1)}
          </Typography>
        </Box>
      </IconButton>
    </Box>
  );
};

export default Persona;