import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../components/Header';
import { Box, Button, Typography } from '@mui/material';
import { auth } from '../Firebase';
import EditProfile from '../components/EditProfile';
import ViewProfile from '../components/ViewProfile';

const Profile = () => {
  const navigate = useNavigate();
  const { uid } = useParams();
  const [isEditMode, setIsEditMode] = useState(false);
  const [user, setUser] = useState(null);
  const [isCurrentUser, setIsCurrentUser] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        setUser(authUser);
        setIsCurrentUser(authUser.uid === uid);
      } else {
        setUser(null);
        setIsCurrentUser(false);
      }
    });

    return () => {
      unsubscribe();
    };
  }, [uid]);

  const handleModeChange = (mode) => {
    setIsEditMode(mode);
  };

  const handleCreateRental = async () => {
    if (user && user.uid) {
      navigate(`/create`);
    } else {
      console.error('User not authenticated');
    }
  };

  if (!user) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '200px',
          textAlign: 'center',
        }}
      >
        <Typography variant="h6" color="error" gutterBottom>
          User not authenticated
        </Typography>
        <Typography variant="body1">
          Please log in to view and edit your profile.
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: '100vh',
        backgroundColor: '#ffffff',
      }}
    >
      <Header />
      {isCurrentUser && (
        <Button
          variant="contained"
          color="secondary"
          fullWidth
          onClick={handleCreateRental}
          sx={{ marginBottom: '2rem' }}
        >
          Create Rental
        </Button>
      )}
      {isCurrentUser && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '2rem',
          }}
        >
          <Button
            variant={isEditMode ? 'contained' : 'outlined'}
            color="primary"
            onClick={() => handleModeChange(true)}
            sx={{
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              marginRight: '-1px',
            }}
          >
            Edit
          </Button>
          <Button
            variant={!isEditMode ? 'contained' : 'outlined'}
            color="primary"
            onClick={() => handleModeChange(false)}
            sx={{
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
            }}
          >
            Preview
          </Button>
        </Box>
      )}
      {isCurrentUser && isEditMode ? (
        <EditProfile user={user} />
      ) : (
        <ViewProfile uid={uid} isCurrentUser={isCurrentUser} />
      )}
    </Box>
  );
};

export default Profile;