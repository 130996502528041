import React, { useState } from 'react';
import { Box, IconButton, Typography, Button, Dialog, List, ListItem, ListItemText } from '@mui/material';
import CalendarIcon from '@mui/icons-material/CalendarToday';
import CalendarSelect from './CalendarSelect';
import Payment from './Payment';
const Rent = ({ rentalId, rentalData, onRentalDatesChange, totalPrice, selectedDates, onSubmit }) => {
  const [openCalendar, setOpenCalendar] = useState(false);
  const [openSummary, setOpenSummary] = useState(false);
  const [isRenting, setIsRenting] = useState(false);
  const handleCalendarOpen = () => setOpenCalendar(true);
  const handleCalendarClose = () => setOpenCalendar(false);
  const handleCloseRentOption = () => setIsRenting(false);
  const handleSummaryToggle = () => setOpenSummary(!openSummary);
  
  console.log("isRenting: ",isRenting)
  return (
    <Box sx={{ position: 'fixed', bottom: 50, left: 0, right: 0, backgroundColor: 'secondary.main', padding: '16px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', zIndex: 1000 }}>
      <Typography variant="h6" sx={{ color: 'white' }}>
        {selectedDates.length > 0 ? `$${totalPrice} total` : `$${totalPrice} per half day`}
      </Typography>
      <IconButton color="inherit" onClick={handleCalendarOpen}>
        <CalendarIcon />
      </IconButton>
      <Button variant="contained" color="primary" disabled={!selectedDates.length} onClick={() => setIsRenting(prev => !prev)}>
        Rent
      </Button>
      {isRenting && (
        <Payment
          rentalData={rentalData}
          rentalId={rentalId}
          onClose={handleCloseRentOption}
          onSubmit={onSubmit}
        />
      )}
      <CalendarSelect
        open={openCalendar}
        onClose={handleCalendarClose}
        rentalId={rentalId}
        availableDates={rentalData.availableDates}
        onRentalDatesChange={onRentalDatesChange}
      />
      <Dialog open={openSummary} onClose={handleSummaryToggle}>
        <List>
          {selectedDates.map((date, index) => (
            <ListItem key={index}>
              <ListItemText primary={`${date.date}: ${date.period}`} />
            </ListItem>
          ))}
        </List>
      </Dialog>
    </Box>
  );
};

export default Rent;