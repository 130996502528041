import React, { useState, useContext } from 'react';
import { Box, Typography, IconButton, Rating, TextField, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { db } from '../Firebase';
import { collection, addDoc } from 'firebase/firestore';
import { UserContext } from '../context/UserProvider';

const StyledBox = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],
}));

const ReviewRental = ({ open, onClose, onSubmit, rentalId }) => {
  const { user } = useContext(UserContext);
  const [rating, setRating] = useState(0);
  const [reviewText, setReviewText] = useState('');

  const handleSubmit = async () => {
    if (!user || !rating) {
      console.error('Error submitting review: Missing required fields');
      return;
    }

    if (!rentalId) {
      console.error('Error submitting review: Missing rentalId');
      return;
    }

    try {
      const reviewData = {
        rentalId,
        userId: user.uid,
        rating,
        createdAt: new Date(),
      };

      if (reviewText) {
        reviewData.reviewText = reviewText;
      }

      await addDoc(collection(db, 'Reviews'), reviewData);
      onSubmit();
      onClose();
    } catch (error) {
      console.error('Error submitting review:', error);
    }
  };

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={onClose}
      onOpen={() => {}}
      swipeAreaWidth={56}
      disableSwipeToOpen={true}
      ModalProps={{
        keepMounted: true,
      }}
    >
      <StyledBox
        sx={{
          position: 'absolute',
          top: -56,
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          visibility: 'visible',
          right: 0,
          left: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          px: 2,
          py: 1,
        }}
      >
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={onClose}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <Typography variant="subtitle1">Close</Typography>
        </IconButton>
        <Typography variant="subtitle1">Review Rental</Typography>
      </StyledBox>
      <StyledBox
        sx={{
          px: 2,
          pb: 2,
          height: '100%',
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box sx={{ mt: 8, mb: 2 }}>
          <Rating
            name="rental-rating"
            value={rating}
            onChange={(event, newValue) => {
              setRating(newValue);
            }}
            size="large"
          />
        </Box>
        <TextField
          multiline
          rows={4}
          placeholder="Write a review (optional)"
          variant="outlined"
          value={reviewText}
          onChange={(e) => setReviewText(e.target.value)}
          sx={{ width: '100%', mb: 2 }}
        />
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </StyledBox>
    </SwipeableDrawer>
  );
};

export default ReviewRental;