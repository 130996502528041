import React from 'react';
import { Box, Typography } from '@mui/material';

const More = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        padding: '16px',
      }}
    >
      <Typography variant="h4" align="center" gutterBottom>
        More Information and Help
      </Typography>
      <Typography variant="body1" align="center">
        Welcome to the More page!
      </Typography>
      <Typography variant="body1" align="center">
        Here, you can find additional information and assistance related to our app.
      </Typography>
      {/* Add more content, links, or sections as needed */}
    </Box>
  );
};

export default More;