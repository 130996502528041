import { useState } from 'react';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import { Button } from '@mui/material';

const Calendar = ({ onAvailableDatesChange, onSaveDates }) => {
  const [selectedDays, setSelectedDays] = useState([]);

  const handleDayClick = (day, { selected, shiftKey }) => {
    if (selected) {
      setSelectedDays(selectedDays.filter((selectedDay) => !isSameDay(selectedDay, day)));
    } else {
      if (shiftKey && selectedDays.length > 0) {
        const lastSelectedDay = selectedDays[selectedDays.length - 1];
        const daysBetween = getDatesBetween(lastSelectedDay, day);
        setSelectedDays([...selectedDays, ...daysBetween]);
      } else {
        setSelectedDays([...selectedDays, day]);
      }
    }
  };

  const isSameDay = (day1, day2) => {
    return day1.getFullYear() === day2.getFullYear() &&
           day1.getMonth() === day2.getMonth() &&
           day1.getDate() === day2.getDate();
  };

  const getDatesBetween = (startDate, endDate) => {
    const dates = [];
    let currentDate = new Date(startDate);
    while (currentDate <= endDate) {
      dates.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  };

  const modifiers = {
    selected: (day) => selectedDays.some((selectedDay) => isSameDay(selectedDay, day)),
  };

  const handleSaveDates = () => {
    const formattedDates = selectedDays.map((day) => day.toISOString());
    onAvailableDatesChange(formattedDates);
    console.log('Selected dates:', formattedDates);
    onSaveDates();
  };

  return (
    <div>
      <DayPicker
        selectedDays={selectedDays}
        onDayClick={handleDayClick}
        modifiers={modifiers}
        modifiersStyles={{
          selected: { backgroundColor: '#F9B418', color: 'white' },
        }}
      />
      <Button
        variant="contained"
        onClick={handleSaveDates}
        sx={{
          backgroundColor: '#F9B418',
          '&:hover': { backgroundColor: '#F9A518' },
          marginTop: '1rem',
        }}
      >
        Save Dates
      </Button>
    </div>
  );
};

export default Calendar;