import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, OAuthProvider } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyDpbT5xwjnao8t9jjUjsQRBZbxH9Ez9lG4",
  authDomain: "wakehorse-696e9.firebaseapp.com",
  projectId: "wakehorse-696e9",
  storageBucket: "wakehorse-696e9.appspot.com",
  messagingSenderId: "547752204646",
  appId: "1:547752204646:web:540788f9392fd16aa750a7",
  measurementId: "G-QFBVESYEL5"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const googleAuthProvider = new GoogleAuthProvider();
const appleAuthProvider = new OAuthProvider('apple.com');

export { auth, db, storage, googleAuthProvider, appleAuthProvider };