import React, { useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  IconButton,
  MenuItem,
} from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import Rentals from '../pages/Rentals';

const Home = () => {
  const [selectedWater, setSelectedWater] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('Boats');

  const handleWaterChange = (event) => {
    setSelectedWater(event.target.value);
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  const waters = [
    { value: '', label: 'Search body of water' },
    { value: 'Crooked Lake', label: 'Crooked Lake' },
    { value: 'Little Traverse Bay', label: 'Little Traverse Bay' },
    { value: 'Walloon Lake', label: 'Walloon Lake' },
  ];

  return (
    <Box>
      <Box sx={{ mb: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <TextField
            select
            variant="outlined"
            value={selectedWater}
            onChange={handleWaterChange}
            fullWidth
            SelectProps={{
              displayEmpty: true,
              renderValue: (selected) => {
                if (selected.length === 0) {
                  return <Typography sx={{ color: 'text.secondary' }}>Search body of water</Typography>;
                }
                return selected;
              },
            }}
            InputProps={{
              startAdornment: (
                <IconButton sx={{ mr: 1 }}>
                  <SearchIcon />
                </IconButton>
              ),
            }}
          >
            {waters.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <IconButton
            color={selectedCategory === 'Boats' ? 'primary' : 'default'}
            onClick={() => handleCategoryClick('Boats')}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              backgroundColor: selectedCategory === 'Boats' ? '#f9b418' : 'transparent',
              '&:hover': {
                backgroundColor: '#f9b418',
              },
              borderRadius: '8px',
              padding: '16px',
            }}
          >
            <img
              src="https://firebasestorage.googleapis.com/v0/b/wakehorse-696e9.appspot.com/o/boat.svg?alt=media&token=cfca8a2b-568c-4b4d-adb6-36c9dd11d08f"
              alt="Boats"
              style={{ width: '48px', height: '48px' }}
            />
            <Typography variant="subtitle2" sx={{ mt: 1 }}>
              Boats
            </Typography>
          </IconButton>
          <IconButton
            color={selectedCategory === 'Jet Skis' ? 'primary' : 'default'}
            onClick={() => handleCategoryClick('Jet Skis')}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              backgroundColor: selectedCategory === 'Jet Skis' ? '#f9b418' : 'transparent',
              '&:hover': {
                backgroundColor: '#f9b418',
              },
              borderRadius: '8px',
              padding: '16px',
            }}
          >
            <img
              src="https://firebasestorage.googleapis.com/v0/b/wakehorse-696e9.appspot.com/o/pwc.svg?alt=media&token=3024e7a1-421d-458f-83db-66801fbb590f"
              alt="Jet Skis"
              style={{ width: '48px', height: '48px' }}
            />
            <Typography variant="subtitle2" sx={{ mt: 1 }}>
              Jet Skis
            </Typography>
          </IconButton>
          <IconButton
            color={selectedCategory === 'Paddlers' ? 'primary' : 'default'}
            onClick={() => handleCategoryClick('Paddlers')}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              backgroundColor: selectedCategory === 'Paddlers' ? '#f9b418' : 'transparent',
              '&:hover': {
                backgroundColor: '#f9b418',
              },
              borderRadius: '8px',
              padding: '16px',
            }}
          >
            <img
              src="https://firebasestorage.googleapis.com/v0/b/wakehorse-696e9.appspot.com/o/paddler.svg?alt=media&token=bdbde7b5-edc4-438e-b8c4-e1c4f2f08d27"
              alt="Paddlers"
              style={{ width: '48px', height: '48px' }}
            />
            <Typography variant="subtitle2" sx={{ mt: 1 }}>
              Paddlers
            </Typography>
          </IconButton>
        </Box>
      </Box>

      <Rentals waters={selectedWater} category={selectedCategory} />
    </Box>
  );
};

export default Home;