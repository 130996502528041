import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#0066cc',
      light: '#63a4ff',
      dark: '#004ba0',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#f9b418',
      light: '#e33371',
      dark: '#9a0036',
      contrastText: '#ffffff',
    },
    tertiary: {
        main: '#bcbcbc',
        light: '#e33371',
        dark: '#9a0036',
        contrastText: '#ffffff',
      },
  },
});

export default theme;