import React, { useState } from 'react';
import { Box, Typography, TextField, Button, IconButton } from '@mui/material';
import { doc, updateDoc, arrayUnion, addDoc, collection,serverTimestamp, Timestamp } from 'firebase/firestore';
import { db } from '../Firebase';
import CloseIcon from '@mui/icons-material/Close';

const ChatModal = ({ renterId, rentalId, userId, onClose }) => {
  const [message, setMessage] = useState('');
  
  const handleSendMessage = async () => {

    try {
      await addDoc(collection(db, 'Chats'), {
        rentalId: rentalId,
        participants: [renterId, userId], // Assume two participants for simplicity
        messages: [{
          userId: userId,
          message: message,
          timestamp: Timestamp.fromDate(new Date()),  // Client-side timestamp
        }],
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp()
      });
      setMessage('');
      onClose();
    } catch (error) {
      console.error('Error sending message:', error);
      // If the document does not exist, create it
    }
  };

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: 'white',
        boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.1)',
        p: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mb: 2,
        }}
      >
        <Typography variant="h6">Send Message</Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <TextField
        multiline
        rows={4}
        placeholder="Type your message..."
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        fullWidth
        sx={{ mb: 2 }}
      />
      <Button 
        variant="contained"
        onClick={handleSendMessage}
        sx={{ mb: 20 }}
      >
        Send
      </Button>
    </Box>
  );
};

export default ChatModal;