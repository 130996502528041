import React, { useState, useEffect, useCallback } from 'react';
import Header from '../components/Header';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Card, CardContent, CardMedia, Button } from '@mui/material';
import { collection, query, where, getDocs, getDoc, doc } from 'firebase/firestore';
import { db } from '../Firebase';
import Persona from '../components/Persona';

const YourListings = ({ userId }) => {
  const navigate = useNavigate();
  const [activeListings, setActiveListings] = useState([]);
  const [inactiveListings, setInactiveListings] = useState([]);
  const [selectedButton, setSelectedButton] = useState('inactive');

  const fetchActiveListings = useCallback(async () => {
    try {
      const bookingsRef = collection(db, 'Bookings');
      const bookingsQuery = query(bookingsRef, where('ownerId', '==', userId), where('status', '==', true));
      const bookingsQuerySnapshot = await getDocs(bookingsQuery);

      const activeListingsData = await Promise.all(
        bookingsQuerySnapshot.docs.map(async (bookingDoc) => {
          const bookingData = bookingDoc.data();
          const rentalRef = doc(db, 'Rentals', bookingData.rentalId);
          const rentalSnapshot = await getDoc(rentalRef);
          const rentalData = rentalSnapshot.data();

          return {
            id: bookingDoc.id,
            ...bookingData,
            ...rentalData,
          };
        })
      );

      setActiveListings(activeListingsData);
    } catch (error) {
      console.error('Error fetching active listings:', error);
    }
  }, [userId]);

  const fetchInactiveListings = useCallback(async () => {
    try {
      const rentalsRef = collection(db, 'Rentals');
      const rentalsQuery = query(rentalsRef, where('userId', '==', userId));
      const rentalsQuerySnapshot = await getDocs(rentalsQuery);

      const rentalsData = rentalsQuerySnapshot.docs.map((rentalDoc) => {
        const rentalData = rentalDoc.data();

        return {
          id: rentalDoc.id,
          ...rentalData,
        };
      });

      setInactiveListings(rentalsData);
    } catch (error) {
      console.error('Error fetching inactive listings:', error);
    }
  }, [userId]);

  useEffect(() => {
    if (userId) {
      fetchActiveListings();
      fetchInactiveListings();
    }
  }, [userId, fetchActiveListings, fetchInactiveListings]);

  const handleButtonClick = (buttonName) => {
    setSelectedButton(buttonName);
  };

  const handleListingClick = (listingId) => {
    if (selectedButton === 'active') {
      navigate(`/your-listing/${listingId}`);
    } else {
      navigate(`/edit-listing/${listingId}`);
    }
  };

  const renderListings = () => {
    const listings = selectedButton === 'active' ? activeListings : inactiveListings;

    return listings.map((listing) => (
      <Card
        key={listing.id}
        sx={{
          mb: 2,
          cursor: 'pointer',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
        onClick={() => handleListingClick(listing.id)}
      >
        <CardMedia
          component="img"
          height="200"
          image={listing.images?.[0] || ''}
          alt={listing.name || ''}
          sx={{ flexShrink: 0 }}
        />
        <CardContent sx={{ flexGrow: 1 }}>
          <Typography variant="h6" gutterBottom>
            {listing.name || ''}
          </Typography>
          <Typography variant="body2" color="text.secondary" gutterBottom>
            {listing.address?.displayName || ''}
          </Typography>
          <Box sx={{ mt: 1 }}>
            <Persona userId={listing.userId || ''} />
          </Box>
        </CardContent>
      </Card>
    ));
  };

  return (
    <Box>
      <Box sx={{ position: 'fixed', top: 0, left: 0, right: 0, zIndex: 999 }}>
        <Header />
      </Box>
      <Box sx={{ pt: 8, pb: 2, textAlign: 'center' }}>
        <Typography variant="h4" gutterBottom>
          Your Listings
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
        <Button
          variant={selectedButton === 'active' ? 'contained' : 'outlined'}
          color="primary"
          onClick={() => handleButtonClick('active')}
          sx={{ mr: 1 }}
        >
          Active
        </Button>
        <Button
          variant={selectedButton === 'inactive' ? 'contained' : 'outlined'}
          color="primary"
          onClick={() => handleButtonClick('inactive')}
        >
          Listed
        </Button>
      </Box>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
          gap: 2,
          px: 2,
        }}
      >
        {renderListings()}
      </Box>
    </Box>
  );
};

export default YourListings;