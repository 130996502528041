import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import { Typography, Box } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import { collection, query, where, getDocs, orderBy } from 'firebase/firestore';
import { db } from '../Firebase';
import { useParams } from 'react-router-dom';
import Persona from './Persona';

const Reviews = () => {
  const { userId } = useParams();
  const [reviews, setReviews] = useState([]);
  const [username, setUsername] = useState('');

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const reviewsSnapshot = await getDocs(
          query(
            collection(db, 'Reviews'),
            where('userId', '==', userId),
            orderBy('createdAt', 'desc')
          )
        );
        const reviewsData = await Promise.all(
          reviewsSnapshot.docs.map(async (doc) => {
            const reviewData = doc.data();

            // Fetch the rental data to get the renterId (reviewer's ID)
            const rentalSnapshot = await getDocs(query(collection(db, 'Bookings'), where('rentalId', '==', reviewData.rentalId)));
            if (!rentalSnapshot.empty) {
              const rentalData = rentalSnapshot.docs[0].data();
              const renterId = rentalData.renterId;

              // Fetch the reviewer's data from the Users collection using the renterId
              const reviewerSnapshot = await getDocs(query(collection(db, 'Users'), where('__name__', '==', renterId)));
              if (!reviewerSnapshot.empty) {
                const reviewerData = reviewerSnapshot.docs[0].data();
                return {
                  ...reviewData,
                  reviewer: {
                    id: reviewerSnapshot.docs[0].id,
                    ...reviewerData,
                  },
                };
              }
            }

            return reviewData;
          })
        );
        setReviews(reviewsData);
      } catch (error) {
        console.error('Error fetching reviews:', error);
      }
    };

    const fetchUsername = async () => {
      try {
        const userDoc = await getDocs(query(collection(db, 'Users'), where('__name__', '==', userId)));
        if (!userDoc.empty) {
          const user = userDoc.docs[0].data();
          setUsername(user.username);
        }
      } catch (error) {
        console.error('Error fetching username:', error);
      }
    };

    fetchReviews();
    fetchUsername();
  }, [userId]);

  return (
    <Box>
      <Header />
      <Typography variant="h6" gutterBottom>
        Reviews for {username}
      </Typography>
      {reviews.map((review, index) => (
        <Box key={index} sx={{ mb: 4 }}>
          {review.reviewer && (
            <Box sx={{ mb: 1 }}>
              <Persona userId={review.reviewer.id} />
            </Box>
          )}
          <Box sx={{ ml: 4 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              {[1, 2, 3, 4, 5].map((star) => (
                <StarIcon
                  key={star}
                  sx={{
                    color: star <= review.rating ? '#F9B418' : 'rgba(0, 0, 0, 0.26)',
                    fontSize: 20,
                  }}
                />
              ))}
            </Box>
            {review.reviewText && (
              <Typography variant="body2" color="text.secondary">
                {review.reviewText}
              </Typography>
            )}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default Reviews;