import React, { useEffect, useState } from 'react';
import { Box, Grid, Card, CardContent, CardMedia, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db, auth } from '../Firebase';
import Persona from '../components/Persona';

const Rentals = ({ waters, category }) => {
  const [rentals, setRentals] = useState([]);
  const currentUser = auth.currentUser;

  useEffect(() => {
    const fetchRentals = async () => {
      try {
        const rentalsRef = collection(db, 'Rentals');
        let q = query(rentalsRef);

        if (currentUser) {
          q = query(q, where('userId', '!=', currentUser.uid));
        }
        if (waters) {
          q = query(rentalsRef, where('water', '==', waters));
        }

        if (category) {
          q = query(q, where('category', '==', category));
        }
  
        console.log("currentUser",currentUser);

        const querySnapshot = await getDocs(q);
        const rentalsData = await Promise.all(
          querySnapshot.docs.map(async (doc) => {
            const rentalData = doc.data();
            return {
              id: doc.id,
              ...rentalData,
            };
          })
        );

        setRentals(rentalsData);
      } catch (error) {
        console.error('Error fetching rentals:', error);
      }
    };

    fetchRentals();
  }, [waters, category]);

  const getFirstImage = (rental) => {
    if (rental.images && rental.images.length > 0) {
      return rental.images[0];
    }
    return null;
  };

  return (
    <Box sx={{ p: 2 }}>
      <Grid container spacing={2} justifyContent="center">
        {rentals.map((rental) => {
          const firstImage = rental.images && rental.images.length > 0 ? rental.images[0] : null;

          return (
            <Grid item xs={12} sm={6} md={4} key={rental.id}>
              <RouterLink to={`/rental/${rental.id}`} style={{ textDecoration: 'none' }}>
                <Card sx={{ maxWidth: 445, position: 'relative' }}>
                  {firstImage && (
                    <CardMedia component="img" height="200" image={firstImage} alt={rental.name} />
                  )}
                  <Box
                    sx={{
                      position: 'absolute',
                      top: 10,
                      right: 10,
                      backgroundColor: 'rgba(255, 255, 255, 0.8)',
                      padding: '4px 8px',
                      borderRadius: '4px',
                    }}
                  >
                    <Typography variant="subtitle1" sx={{ color: '#000000' }}>
                      ${rental.price}
                    </Typography>
                  </Box>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      {rental.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {rental.address.displayName}
                    </Typography>
                    <Persona userId={rental.userId} />
                  </CardContent>
                </Card>
              </RouterLink>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default Rentals;