import React from 'react';
import { Box, IconButton } from '@mui/material';
import { Home as HomeIcon, Chat as ChatIcon, Person as ProfileIcon, MoreHoriz as MoreIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const Footer = ({ user }) => {
  const navigate = useNavigate();

  const handleHomeClick = () => {
    navigate('/');
  };

  const handleChatClick = () => {
    if (user) {
      navigate(`/conversations`);
    } else {
      // Handle the case when the user is not authenticated
      navigate('/login');
    }
    console.log('Chat icon clicked');
  };

  const handleProfileClick = () => {
    if (user) {
      navigate(`/profile/${user.uid}`);
    } else {
      // Handle the case when the user is not authenticated
      navigate('/login');
    }
  };

  const handleMoreClick = () => {
    navigate('/more');
  };

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: 'primary.main',
        padding: '8px',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        zIndex: 1000,
      }}
    >
      {user ?
      <>
        <IconButton color="inherit" onClick={handleHomeClick}>
          <HomeIcon />
        </IconButton>
        <IconButton color="inherit" onClick={handleChatClick}>
        <ChatIcon />
      </IconButton>
        <IconButton color="inherit" onClick={handleProfileClick}>
          <ProfileIcon />
        </IconButton>
        <IconButton color="inherit" onClick={handleMoreClick}>
          <MoreIcon />
        </IconButton>
      </>
      : 
      <>
        <IconButton color="inherit" onClick={handleHomeClick}>
          <HomeIcon />
        </IconButton>
        <IconButton color="inherit" onClick={handleProfileClick}>
          <ProfileIcon />
        </IconButton>
        <IconButton color="inherit" onClick={handleMoreClick}>
          <MoreIcon />
        </IconButton>
      </>
      }

    </Box>
  );
};

export default Footer;