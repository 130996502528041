import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Card,
  CardContent,
  Button,
  CardMedia,
} from '@mui/material';
import { GoogleMap, MarkerF } from '@react-google-maps/api';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import { db } from '../Firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReviewRental from '../components/ReviewRental';
import Persona from '../components/Persona';

const YourListing = () => {
  const { listingId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const refreshListings = location.state?.refreshListings;

  const [listingData, setListingData] = useState(null);
  const [rentalData, setRentalData] = useState(null);
  const [openReviewDrawer, setOpenReviewDrawer] = useState(false);
  useEffect(() => {
    const fetchListingData = async () => {
      try {
        const listingRef = doc(db, 'Bookings',listingId);
        const listingSnapshot = await getDoc(listingRef);
        if (listingSnapshot.exists()) {
          const bookingData = listingSnapshot.data();
          setListingData(bookingData);

          const rentalRef = doc(db, 'Rentals', bookingData.rentalId);
          const rentalSnapshot = await getDoc(rentalRef);
          if (rentalSnapshot.exists()) {
            const rental = rentalSnapshot.data();
            setRentalData(rental);
          }
        }
      } catch (error) {
        console.error('Error fetching listing data:', error);
      }
    };

    if (listingId) {
      fetchListingData();
    }
  }, [listingId]);

  const handleMarkAsComplete = async () => {
    try {
      const listingRef = doc(db, 'Bookings', listingId);
      await updateDoc(listingRef, { status: true });
      setOpenReviewDrawer(true);
    } catch (error) {
      console.error('Error marking listing as complete:', error);
    }
  };

  const handleReviewSubmit = () => {
    setOpenReviewDrawer(false);
    if (refreshListings) {
      refreshListings();
    }
    navigate('/your-listings');
  };

  const handleOwnerProfileClick = () => {
    navigate(`/profile/${listingData.ownerId}`);
  };

  const handleRenterProfileClick = () => {
    navigate(`/profile/${listingData.renterId}`);
  };

  if (!listingData || !rentalData) {
    return <Typography>Loading...</Typography>;
  }

  const { dates, totalPrice } = listingData;

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Header />
      <Typography variant="h4" gutterBottom>
        {rentalData.name}
      </Typography>
      <Card sx={{ maxWidth: 600, width: '100%', paddingBottom: 2, marginBottom: 6 }}>
        <Slider {...settings}>
          {rentalData.images.map((image, index) => (
            <CardMedia
              key={index}
              component="img"
              height="300"
              image={image}
              alt={`${rentalData.name} ${index + 1}`}
            />
          ))}
        </Slider>
        <CardContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: 2 }}>
            <Typography variant="subtitle1" gutterBottom>
              Owner
            </Typography>
            <Box onClick={handleOwnerProfileClick} style={{ cursor: 'pointer' }}>
              <Persona userId={listingData.ownerId} />
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: 2 }}>
            <Typography variant="subtitle1" gutterBottom>
              Renter
            </Typography>
            <Box onClick={handleRenterProfileClick} style={{ cursor: 'pointer' }}>
              <Persona userId={listingData.renterId} />
            </Box>
          </Box>
          <Typography variant="subtitle1" gutterBottom>
            Selected Dates:
          </Typography>
          <ul>
            {dates.map((date, index) => (
              <li key={index}>{`${date.date}: ${date.period}`}</li>
            ))}
          </ul>
          <Typography variant="subtitle1" gutterBottom>
            Total Price: ${totalPrice}
          </Typography>
          <Box sx={{ height: 300, marginTop: 2 }}>
            <GoogleMap
              mapContainerStyle={{ width: '100%', height: '100%' }}
              center={{ lat: rentalData.address.location.lat, lng: rentalData.address.location.lng }}
              zoom={12}
            >
              <MarkerF
                position={{ lat: rentalData.address.location.lat, lng: rentalData.address.location.lng }}
              />
            </GoogleMap>
          </Box>
        </CardContent>
      </Card>
      {listingData.status === false && (
        <Button variant="contained" color="primary" sx={{ marginBottom: 2 }} onClick={handleMarkAsComplete}>
          Mark as Complete
        </Button>
      )}
      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          padding: 2,
          backgroundColor: 'background.paper',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <ChatBubbleIcon />
      </Box>
      <ReviewRental
        open={openReviewDrawer}
        onClose={() => setOpenReviewDrawer(false)}
        onSubmit={handleReviewSubmit}
        rentalId={listingData.rentalId}
      />
    </Box>
  );
};

export default YourListing;