import React, { useEffect, useState } from 'react';
import { Box, Typography, List, ListItem, ListItemText, Divider } from '@mui/material';
import { collection, query, where, onSnapshot, getDocs, getDoc, doc } from 'firebase/firestore';
import { db, auth } from '../Firebase';
import {Link} from 'react-router-dom';

const Conversations = () => {
  const [conversations, setConversations] = useState([]);
  const user = auth.currentUser;
  console.log(conversations);
  useEffect(() => {
    if (!user) return;  // Check if the user is not logged in

    const fetchConversations = async () => {
      const conversationsRef = collection(db, 'Chats');
      const q = query(conversationsRef, where('participants', 'array-contains', user.uid));

      const unsubscribe = onSnapshot(q, async (snapshot) => {
        const fetches = snapshot.docs.map(async (docSnapshot) => {
          const conversation = docSnapshot.data();
          const rentalDocRef = doc(db, 'Rentals',conversation.rentalId); // Get a reference to the rental document
          const rentalSnapshot = await getDoc(rentalDocRef);
          console.log(rentalSnapshot.data());
          return {
            id: docSnapshot.id,
            ...conversation,
            bookingData: rentalSnapshot.exists() ? rentalSnapshot.data() : "UNLISTED", // Access rental data safely
          };

        });

        Promise.all(fetches).then(setConversations);  // Resolve all promises and set conversations
      });

      return () => unsubscribe();
    };

    fetchConversations();
  }, [user]);  

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Conversations
      </Typography>
      {conversations.length > 0 && (
        
          <List>
          {conversations.map((conversation) => (
            <React.Fragment key={conversation.id}>
              <Link to={`/conversation/${conversation.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                <ListItem>
                  <ListItemText
                    primary={`Rental: ${conversation.bookingData.name}`}
                    secondary={conversation.messages[conversation.messages.length - 1].message}
                    />
                </ListItem>
              </Link>
              <Divider />
            </React.Fragment>
          ))}
        </List>

      )}
    </Box>
  );
};

export default Conversations;



