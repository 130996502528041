/* global google */
import React, { useEffect, useRef, useState } from 'react';
import { TextField, Grid } from '@mui/material';

const AddressComponent = ({ onAddressSelect, fullWidth, required, sx }) => {
  const [isAPILoaded, setAPILoaded] = useState(false);
  const autocompleteRef = useRef(null);

  useEffect(() => {
    const loadGoogleMapsScript = () => {
      if (window.google) {
        setAPILoaded(true);
        return;
      }
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDrfAjWJk7smHW1uzl1Cpc2uAadvrnm3sI&libraries=places`;
      script.async = true;
      script.onload = () => setAPILoaded(true);
      document.head.appendChild(script);
    };

    loadGoogleMapsScript();
  }, []);

  useEffect(() => {
    if (isAPILoaded && autocompleteRef.current) {
      const autocomplete = new google.maps.places.Autocomplete(autocompleteRef.current);
      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        if (place.geometry) {
          onAddressSelect({
            id: place.place_id,
            displayName: place.name,
            formattedAddress: place.formatted_address || "",
            location: {
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng()
            }
          });
        } else {
          console.error("No details available for input: '" + place.name + "'");
        }
      });
    }
  }, [isAPILoaded, onAddressSelect]);

  return (
    <Grid item xs={12}>
      <TextField
        fullWidth={fullWidth}
        required={required}
        sx={{ marginBottom: '1rem', ...sx }}
        label="Address"
        placeholder="Type address..."
        inputRef={autocompleteRef}
        InputProps={{
          readOnly: !isAPILoaded,
        }}
        helperText={isAPILoaded ? null : 'Loading address input...'}
      />
    </Grid>
  );
};

export default AddressComponent;