import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import '@googlemaps/extended-component-library/api_loader.js';
import Footer from './components/Footer';
import Home from './pages/Home';
import Login from './pages/Login';
import Create from './pages/Create';
import Profile from './pages/Profile';
import Rental from './pages/Rental';
import Conversations from './pages/Conversations';
import Conversation from './pages/Conversation';
import More from './pages/More';
import YourRentals from './pages/YourRentals';
import YourListings from './pages/YourListings';
import YourListing from './pages/YourListing';
import YourRental from './pages/YourRental';
import EditRental from './components/EditRental';
import Reviews from './components/Reviews';
import theme from './components/Theme';
import { auth } from './Firebase';
import { UserProvider } from './context/UserProvider';

function App() {
  const [user, setUser] = React.useState(null);

  React.useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        setUser(authUser);
      } else {
        setUser(null);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <UserProvider>
          <Box sx={{ display: 'flex', p: 3, flexDirection: 'column', minHeight: '100vh' }}>
            <script src={`https://maps.googleapis.com/maps/api/js?key=AIzaSyDrfAjWJk7smHW1uzl1Cpc2uAadvrnm3sI&libraries=marker&v=beta`}></script>

            <Box component="main" sx={{ flexGrow: 1 }}>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/login" element={<Login />} />
                <Route path="/update/:rentalId" element={<Create user={user} />} />
                <Route path="/create" element={<Create user={user} />} />
                <Route path="/profile/:uid" element={<Profile />} />
                <Route path="/profile/:uid/rentals/:rentalId" element={<Create user={user} />} />
                <Route path="/rental/:id" element={<Rental />} />
                <Route path="/conversations" element={<Conversations />} />
                <Route path="/more" element={<More />} />
                <Route path="/your-rentals" element={<YourRentals userId={user?.uid} />} />
                <Route path="/your-listings" element={<YourListings userId={user?.uid} />} />
                <Route path="/your-listing/:listingId" element={<YourListing />} />
                <Route path="/your-rental/:rentalId" element={<YourRental />} />
                <Route path="/edit-listing/:rentalId" element={<EditRental />} />
                <Route path="/reviews/:userId" element={<Reviews />} />
                <Route path="/conversation/:conversationId" element={<Conversation />} />
              </Routes>
            </Box>
            <Footer user={user} />
          </Box>
        </UserProvider>
      </ThemeProvider>
    </Router>
  );
}

export default App;