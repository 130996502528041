import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Card, CardContent, CardMedia, Button } from '@mui/material';
import { collection, query, where, getDocs, getDoc, doc } from 'firebase/firestore';
import { db } from '../Firebase';

const YourRentals = ({ userId }) => {
  const navigate = useNavigate();
  const [currentRentals, setCurrentRentals] = useState([]);
  const [pastRentals, setPastRentals] = useState([]);
  const [selectedButton, setSelectedButton] = useState('current');
  console.log('currentRentals Data:', currentRentals);

  const fetchCurrentRentals = useCallback(async () => {
    try {
      const bookingsRef = collection(db, 'Bookings');
      const bookingsQuery = query(bookingsRef, where('renterId', '==', userId), where('renterReview', '==', false));
      const bookingsQuerySnapshot = await getDocs(bookingsQuery);

      const currentRentalsData = await Promise.all(
        bookingsQuerySnapshot.docs.map(async (bookingDoc) => {
          const bookingData = bookingDoc.data();
          const rentalRef = doc(db, 'Rentals', bookingData.rentalId);
          const rentalSnapshot = await getDoc(rentalRef);
          const rentalData = rentalSnapshot.data();

          return {
            id: bookingDoc.id,
            ...bookingData,
            rentalData,
          };
        })
      );

      setCurrentRentals(currentRentalsData);
    } catch (error) {
      console.error('Error fetching current rentals:', error);
    }
  }, [userId]);

  const fetchPastRentals = useCallback(async () => {
    try {
      const bookingsRef = collection(db, 'Bookings');
      const bookingsQuery = query(bookingsRef, where('renterId', '==', userId), where('renterReview', '==', true));
      const bookingsQuerySnapshot = await getDocs(bookingsQuery);

      const pastRentalsData = await Promise.all(
        bookingsQuerySnapshot.docs.map(async (bookingDoc) => {
          const bookingData = bookingDoc.data();
          const rentalRef = doc(db, 'Rentals', bookingData.rentalId);
          const rentalSnapshot = await getDoc(rentalRef);
          const rentalData = rentalSnapshot.data();

          return {
            id: bookingDoc.id,
            ...bookingData,
            rentalData,
          };
        })
      );

      setPastRentals(pastRentalsData);
    } catch (error) {
      console.error('Error fetching past rentals:', error);
    }
  }, [userId]);

  useEffect(() => {
    if (userId) {
      fetchCurrentRentals();
      fetchPastRentals();
    }
  }, [userId, fetchCurrentRentals, fetchPastRentals]);

  const handleButtonClick = (buttonName) => {
    setSelectedButton(buttonName);
  };

  const handleRentalClick = (rentalId) => {
    navigate(`/your-rental/${rentalId}`);
  };

  const renderRentals = () => {
    const rentals = selectedButton === 'current' ? currentRentals : pastRentals;

    return rentals.map((rental) => (
      <Card
        key={rental.id}
        sx={{
          mb: 2,
          cursor: 'pointer',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
        onClick={() => handleRentalClick(rental.id)}
      >
        <CardMedia
          component="img"
          height="200"
          image={rental.rentalData?.images?.[0] || ''}
          alt={rental.rentalData?.name || ''}
          sx={{ flexShrink: 0 }}
        />
        <CardContent sx={{ flexGrow: 1 }}>
          <Typography variant="h6" gutterBottom>
            {rental.rentalData?.name || ''}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {rental.rentalData?.address?.displayName || ''}
          </Typography>
        </CardContent>
      </Card>
    ));
  };

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h4" gutterBottom>
        Your Rentals
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
        <Button
          variant={selectedButton === 'current' ? 'contained' : 'outlined'}
          color="primary"
          onClick={() => handleButtonClick('current')}
          sx={{ mr: 1 }}
        >
          Current
        </Button>
        <Button
          variant={selectedButton === 'past' ? 'contained' : 'outlined'}
          color="primary"
          onClick={() => handleButtonClick('past')}
        >
          Past
        </Button>
      </Box>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
          gap: 2,
        }}
      >
        {renderRentals()}
      </Box>
    </Box>
  );
};

export default YourRentals;